




































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import SubArrayForm from "../SubArrayForm.vue";
import Loader from "../Loader.vue";
import { Guid } from "guid-typescript";

@Component({
  components: {
    FormContainer,
    SubArrayForm,
    Loader
  }
})
export default class ResultFrameworkBreakdownForm extends Vue {
  @Prop()
  public id!: string;
  @Prop()
  public name!: string;

  @Prop()
  public routeAfterSave!: (id: any) => Location;

  @Prop()
  public routeAfterSaveAndClose!: (id: any) => Location;
  @Prop()
  public provider!: DataProvider<any>;
  @Prop()
  public crud!: ICrudClient<any>;
  private item: any = null;

  async mounted() {
    if (this.id === "new") {
      this.item = {
        id: Guid.create().toString(),
        name: "",
        options: []
      };
    }
  }
}
